const loadScriptAndCss = async () => {
  try {
    // Define the base URL
    const baseUrl = 'https://kia-pages.vercel.app';

    // Fetch the HTML file with cache disabled
    const response = await fetch(baseUrl, {
      cache: 'no-store',
    });

    if (!response.ok) throw new Error('Failed to fetch index.html');

    // Parse the HTML content as text
    const htmlContent = await response.text();

    // Create a temporary DOM parser to extract elements
    const parser = new DOMParser();
    const htmlDocument = parser.parseFromString(htmlContent, 'text/html');

    // Find all CSS link elements and append them to the document head
    const cssLinks = htmlDocument.querySelectorAll('link[rel="stylesheet"]');
    cssLinks.forEach((link) => {
      const relativeHref = (link as HTMLLinkElement).getAttribute('href') || '';
      const cssLink = document.createElement('link');
      cssLink.rel = 'stylesheet';
      // Construct full URL using baseUrl
      cssLink.href = `${baseUrl}${relativeHref}`;
      // Check if the CSS link is already in the document
      if (!document.querySelector(`link[href="${cssLink.href}"]`)) {
        document.head.appendChild(cssLink);
      }
    });

    // Find all script elements and dynamically load them
    const scriptTags = htmlDocument.querySelectorAll('script[src]');
    scriptTags.forEach((script) => {
      const relativeSrc =
        (script as HTMLScriptElement).getAttribute('src') || '';
      const scriptElement = document.createElement('script');
      // Construct full URL using baseUrl
      scriptElement.src = `${baseUrl}${relativeSrc}`;
      scriptElement.type = (script as HTMLScriptElement).type;
      scriptElement.async = false; // To preserve execution order
      // Check if the script is already in the document
      if (!document.querySelector(`script[src="${scriptElement.src}"]`)) {
        document.body.appendChild(scriptElement);
      }
    });
  } catch (error) {
    console.error('Error loading files:', error);
  }
};

// Execute the function to load scripts and styles
loadScriptAndCss();
